import type { FC } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { List, ListItem, ListItemIcon } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import Image from "next/image";
import ElectionImage from "/public/assets/home/election.webp";
import ChatImage from "/public/assets/home/chat.webp";
import CalendarImage from "/public/assets/home/calendar.webp";
import ActionStrikeImage from "/public/assets/home/action-strike.webp";

interface Feature {
   id: string;
   title: string;
   description: JSX.Element;
   image: JSX.Element;
}

const features: Feature[] = [
   {
      id: "2",
      title: "Realtime Chat",
      description: (
         <Box>
            <Typography color="inherit"
variant="body2">
               Our intuitive chat feature allows members to engage in open
               discussions, share ideas, and strategize together.
            </Typography>
            <List sx={{ listStyleType: "disc" }}>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Stay up-to-date with real-time chat" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Create closed group discussions" />
               </ListItem>
            </List>
         </Box>
      ),
      image: (
         <Image
            src={ChatImage}
            alt="Picture of the chat screen"
            sizes="100vw"
            style={{
               width: "100%",
               height: "auto",
            }}
         />
      ),
   },
   {
      id: "3",
      title: "Union Calendar",
      description: (
         <Box>
            <Typography color="inherit"
variant="body2">
               The shared calendar ensures everyone is up-to-date on important
               events, votes, and milestones, fostering transparency and
               accountability within the union.
            </Typography>
            <List sx={{ listStyleType: "disc" }}>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Stay informed on important union dates" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Automatically keeps track of voting and election timelines" />
               </ListItem>
            </List>
         </Box>
      ),
      image: (
         <Image
            src={CalendarImage}
            alt="Picture of the calendar screen"
            sizes="100vw"
            style={{
               width: "100%",
               height: "auto",
            }}
         />
      ),
   },
   {
      id: "4",
      title: "Vote on Union Changes & Collective Actions",
      description: (
         <Box>
            <Typography color="inherit"
variant="body2">
               Every voice matters, and with our secure voting system, you can
               ensure fair and democratic decision-making that reflects the will
               of the union.
            </Typography>
            <List sx={{ listStyleType: "disc" }}>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Vote on union changes" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Vote on collective actions (strike, slowdown, picketing, etc)" />
               </ListItem>
            </List>
         </Box>
      ),
      image: (
         <Image
            src={ActionStrikeImage}
            alt="Picture of the actions screen with a strike selected"
            sizes="100vw"
            style={{
               width: "100%",
               height: "auto",
            }}
         />
      ),
   },
   {
      id: "5",
      title: "Elect Union Leadership",
      description: (
         <Box>
            <Typography color="inherit"
variant="body2">
               Our platform supports a seamless and secure process for electing
               representatives. Empower your members to participate in shaping
               the future of the union by facilitating transparent and
               trustworthy elections.
            </Typography>
            <List sx={{ listStyleType: "disc" }}>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Propose to replace leadership" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Elect leadership roles" />
               </ListItem>
            </List>
         </Box>
      ),
      image: (
         <Image
            src={ElectionImage}
            alt="Picture of the elections screen"
            sizes="100vw"
            style={{
               width: "100%",
               height: "auto",
            }}
         />
      ),
   },
];

export const ToolForOrganizers: FC = () => {
   const [activeFeature, setActiveFeature] = useState<number>(0);
   const feature = features[activeFeature];

   return (
      <Box
         sx={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundImage: 'url("/assets/gradient-bg.svg")',
            py: "120px",
         }}
      >
         <Container maxWidth="lg">
            <Stack
               spacing={2}
               sx={{
                  mb: 8,
               }}
            >
               <Typography align="center"
color="inherit"
variant="h2">
                  Empowering union organizers with great tools.
               </Typography>
               <Typography align="center"
color="inherit"
variant="subtitle1">
                  Our goal is to make the process of managing your union just a
                  little bit easier.
               </Typography>
            </Stack>
            <Grid alignItems="center"
container
spacing={3}>
               <Grid xs={12}
md={6}>
                  <Stack spacing={1}>
                     {features.map((feature, index) => {
                        const isActive = activeFeature === index;

                        return (
                           <Box
                              key={feature.id}
                              onClick={() => setActiveFeature(index)}
                              sx={{
                                 borderRadius: 2.5,

                                 cursor: "pointer",
                                 p: 3,
                                 transition: (theme) =>
                                    theme.transitions.create(
                                       [
                                          "background-color, box-shadow",
                                          "color",
                                       ],
                                       {
                                          easing:
                                             theme.transitions.easing.easeOut,
                                          duration:
                                             theme.transitions.duration
                                                .enteringScreen,
                                       },
                                    ),
                                 ...(isActive && {
                                    backgroundColor: "primary.alpha12",
                                    boxShadow: (theme) =>
                                       `${theme.palette.primary.main} 0 0 0 1px`,
                                 }),
                                 "&:hover": {
                                    ...(!isActive && {
                                       backgroundColor: "primary.alpha4",
                                       boxShadow: (theme) =>
                                          `${theme.palette.primary.main} 0 0 0 1px`,
                                    }),
                                 },
                              }}
                           >
                              <Typography
                                 color="inherit"
                                 sx={{ mb: 1 }}
                                 variant="h6"
                              >
                                 {feature.title}
                              </Typography>
                              {feature.description}
                           </Box>
                        );
                     })}
                  </Stack>
               </Grid>
               <Grid xs={12}
md={6}>
                  {feature.image}
               </Grid>
            </Grid>
         </Container>
      </Box>
   );
};
