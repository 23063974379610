import type { NextPage } from "next";

import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { Layout as MarketingLayout } from "src/layouts/marketing";
import { HomeCta } from "src/sections/home/home-cta";
import { HomeHero } from "src/sections/home/home-hero";
import { ToolForOrganizers } from "../sections/home/tools-for-organizers";
import React from "react";
import { ToolsForUsers } from "../sections/home/tools-for-users";
const Page: NextPage = () => {
   usePageView();

   return (
      <>
         <Seo
            title={"Discovering and Joining Labor Unions Made Easy"}
            description={
               "Join our platform that makes discovering, joining, or even creating a local labor union easy. Take action for fair wages, benefits, and working conditions."
            }
         />
         <main>
            <HomeHero />
            <ToolsForUsers />
            <ToolForOrganizers />
            <HomeCta />
            {/*<HomeReviews />*/}
            {/*<HomeFaqs />*/}
            {/*<div id="fb-root"></div>*/}
            {/*<div id="fb-customer-chat" className="fb-customerchat"></div>*/}
            {/*<Script id="chat" strategy="lazyOnload">*/}
            {/*   {`  */}
            {/*     var chatbox = document.getElementById('fb-customer-chat');*/}
            {/*     chatbox.setAttribute("page_id", "100202529761224");*/}
            {/*     chatbox.setAttribute("attribution", "biz_inbox");*/}
            {/*     */}
            {/*     window.fbAsyncInit = function() {*/}
            {/*   FB.init({*/}
            {/*      xfbml            : true,*/}
            {/*      version          : 'v18.0'*/}
            {/*    });*/}
            {/*  };*/}

            {/*  (function(d, s, id) {*/}
            {/*    var js, fjs = d.getElementsByTagName(s)[0];*/}
            {/*    if (d.getElementById(id)) return;*/}
            {/*    js = d.createElement(s); js.id = id;*/}
            {/*    js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';*/}
            {/*    fjs.parentNode.insertBefore(js, fjs);*/}
            {/*  }(document, 'script', 'facebook-jssdk'));*/}
            {/* */}
            {/*   `}*/}
            {/*</Script>*/}
         </main>
      </>
   );
};

Page.getLayout = (page) => <MarketingLayout>{page}</MarketingLayout>;

export default Page;
