import type { FC } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { RouterLink } from "../../components/router-link";
import { paths } from "../../paths";

export const HomeCta: FC = () => (
   <Box
      sx={{
         backgroundColor: "neutral.800",
         backgroundRepeat: "no-repeat",
         backgroundPosition: "top center",
         backgroundImage: 'url("/assets/gradient-bg.svg")',
         color: "neutral.100",
         py: "120px",
      }}
   >
      <Container maxWidth="lg">
         <Stack spacing={2}>
            <Typography align="center"
color="inherit"
variant="h3">
               "The only effective answer to organized greed is organized
               labor."
            </Typography>
            <Typography align="center"
color="inherit"
variant="subtitle2">
               - Thomas Donahue (Secretary-Treasurer of the American Federation
               of Labor)
            </Typography>
         </Stack>
         <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 3 }}
         >
            <Button
               variant="contained"
               component={RouterLink}
               href={paths.dashboard.index}
            >
               Start Today
            </Button>
         </Stack>
      </Container>
   </Box>
);
