import type { FC } from "react";
import { alpha } from "@mui/system/colorManipulator";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import dynamic from "next/dynamic";
import { Skeleton } from "@mui/material";
import { Fullscreen } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { RouterLink } from "../../components/router-link";
import { paths } from "../../paths";
const UnionMap = dynamic(() => import("../../sections/map/home-map"), {
   ssr: false,
   loading: () => <Skeleton variant="rounded"
animation="wave"
height={395} />,
});
export const HomeMapSample: FC = () => {
   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
         }}
      >
         <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            sx={{
               backdropFilter: "blur(6px)",
               backgroundColor: (theme) =>
                  alpha(theme.palette.neutral[800], 0.95),
               borderBottomColor: "neutral.700",
               borderBottomStyle: "solid",
               borderBottomWidth: 1,
               borderTopLeftRadius: (theme) => theme.shape.borderRadius,
               borderTopRightRadius: (theme) => theme.shape.borderRadius,
               boxShadow: 24,
               flex: "0 0 auto",
               overflow: "hidden",
               px: 2,
            }}
         >
            <Stack
               alignItems="center"
               direction="row"
               spacing={1}
               sx={{
                  py: 2,
                  "& > div": {
                     backgroundColor: "rgba(255, 255, 255, 0.16)",
                     borderRadius: "50%",
                     height: 10,
                     width: 10,
                  },
               }}
            >
               <div />
               <div />
               <div />
            </Stack>
            <Stack alignItems="center"
direction="row"
spacing={1}>
               <Button
                  component={RouterLink}
                  href={paths.map}
                  sx={{ color: "neutral.50" }}
                  size="small"
                  endIcon={
                     <Fullscreen
                        sx={{ color: "neutral.50" }}
                        color="primary"
                     ></Fullscreen>
                  }
               >
                  Full Screen
               </Button>
            </Stack>
         </Stack>
         <Box
            sx={{
               backdropFilter: "blur(6px)",
               backgroundColor: (theme) =>
                  alpha(theme.palette.neutral[800], 0.9),
               borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
               borderBottomRightRadius: (theme) => theme.shape.borderRadius,
               flex: "1 1 auto",
               overflow: "hidden",
               p: 1,
               "& pre": {
                  background: "none !important",
                  borderRadius: "0 !important",
                  fontSize: "12px !important",
                  height: "100%",
                  m: "0 !important",
                  overflow: "hidden !important",
                  p: "0 !important",
               },
               "& code": {
                  fontSize: "12px !important",
               },
            }}
         >
            <UnionMap height={"395px"} />
            {/*<SyntaxHighlighter*/}
            {/*  language={lang}*/}
            {/*  style={codeStyle}*/}
            {/*>*/}
            {/*  {JSON.parse(code)}*/}
            {/*</SyntaxHighlighter>*/}
         </Box>
      </Box>
   );
};
