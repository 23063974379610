import type { FC } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { List, ListItem, ListItemIcon } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import Image from "next/image";
import LocationImage from "/public/assets/home/location.webp";
import ReviewsImage from "/public/assets/home/reviews.webp";
import BenefitsImage from "/public/assets/home/benefits.webp";

interface Feature {
   id: string;
   title: string;
   description: JSX.Element;
   image: JSX.Element;
}

const features: Feature[] = [
   {
      id: "1",
      title: "Discover & Join Unions Effortlessly",
      description: (
         <>
            <Typography color="inherit"
variant="body2">
               Our vision is to make the process of discovering and joining
               labor unions to be a seamless and straightforward experience.
            </Typography>
            <List sx={{ listStyleType: "disc" }}>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Easily Find Unions by Address" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Simplified Click & Join Union" />
               </ListItem>
            </List>
         </>
      ),
      image: (
         <Image
            src={LocationImage}
            alt="Picture of the location screen"
            sizes="100vw"
            style={{
               width: "100%",
               height: "auto",
            }}
         />
      ),
   },
   {
      id: "2",
      title: "Share, Compare, and Ensure Fair Pay",
      description: (
         <Box>
            <Typography color="inherit"
variant="body2">
               We believe when workers come together and share information about
               how they are compensated everyone wins.
            </Typography>
            <List sx={{ listStyleType: "disc" }}>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Share & Compare Benefits" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Information Relevant to Job & Experience" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Transparent Compensation" />
               </ListItem>
            </List>
         </Box>
      ),
      image: (
         <Image
            src={BenefitsImage}
            alt="Picture of the benefits screen"
            sizes="100vw"
            style={{
               width: "100%",
               height: "auto",
            }}
         />
      ),
   },
   {
      id: "3",
      title: "Share Your Employment Experiences",
      description: (
         <Box>
            <Typography color="inherit"
variant="body2">
               Whether you need to rant or review before getting involved with
               an employer it pays to see what others are saying about the
               experience.
            </Typography>
            <List sx={{ listStyleType: "disc" }}>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Share Your Employment Story" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Make Informed Decisions" />
               </ListItem>
            </List>
         </Box>
      ),
      image: (
         <Image
            src={ReviewsImage}
            alt="Picture of the reviews screen"
            sizes="100vw"
            style={{
               width: "100%",
               height: "auto",
            }}
         />
      ),
   },
];

export const ToolsForUsers: FC = () => {
   const [activeFeature, setActiveFeature] = useState<number>(0);
   const feature = features[activeFeature];

   return (
      <Box
         sx={{
            backgroundColor: "neutral.800",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundImage: 'url("/assets/gradient-bg.svg")',
            color: "common.white",
            py: "120px",
         }}
      >
         <Container maxWidth="lg">
            <Stack
               spacing={2}
               sx={{
                  mb: 8,
                  mt: {
                     xs: 15,
                     sm: 2,
                     md: 0,
                     lg: 0,
                     xl: 0,
                  },
               }}
            >
               <Typography align="center"
color="inherit"
variant="h2">
                  Everything you need to start organizing.
               </Typography>
               <Typography align="center"
color="inherit"
variant="subtitle1">
                  Our platform makes it easy to get organized while protecting
                  your privacy.
               </Typography>
            </Stack>
            <Grid alignItems="center"
container
spacing={3}>
               <Grid xs={12}
md={6}>
                  <Stack spacing={1}>
                     {features.map((feature, index) => {
                        const isActive = activeFeature === index;

                        return (
                           <Box
                              key={feature.id}
                              onClick={() => setActiveFeature(index)}
                              sx={{
                                 borderRadius: 2.5,
                                 color: "neutral.400",
                                 cursor: "pointer",
                                 p: 3,
                                 transition: (theme) =>
                                    theme.transitions.create(
                                       [
                                          "background-color, box-shadow",
                                          "color",
                                       ],
                                       {
                                          easing:
                                             theme.transitions.easing.easeOut,
                                          duration:
                                             theme.transitions.duration
                                                .enteringScreen,
                                       },
                                    ),
                                 ...(isActive && {
                                    backgroundColor: "primary.alpha12",
                                    boxShadow: (theme) =>
                                       `${theme.palette.primary.main} 0 0 0 1px`,
                                    color: "common.white",
                                 }),
                                 "&:hover": {
                                    ...(!isActive && {
                                       backgroundColor: "primary.alpha4",
                                       boxShadow: (theme) =>
                                          `${theme.palette.primary.main} 0 0 0 1px`,
                                       color: "common.white",
                                    }),
                                 },
                              }}
                           >
                              <Typography
                                 color="inherit"
                                 sx={{ mb: 1 }}
                                 variant="h6"
                              >
                                 {feature.title}
                              </Typography>
                              {feature.description}
                              {/*{feature.id === "figma" && (*/}
                              {/*   <Box sx={{ mt: 3 }}>*/}
                              {/*      <Button*/}
                              {/*         color="success"*/}
                              {/*         component="a"*/}
                              {/*         endIcon={*/}
                              {/*            <SvgIcon fontSize="small">*/}
                              {/*               <LinkExternal01Icon />*/}
                              {/*            </SvgIcon>*/}
                              {/*         }*/}
                              {/*         href="https://www.figma.com/file/xrx6uDljzsWuDZiuz3ITCp/Renter-Kit-Pro-UI-6.0-Master"*/}
                              {/*         size="small"*/}
                              {/*         target="_blank"*/}
                              {/*         variant="contained"*/}
                              {/*      >*/}
                              {/*         Preview in Figma*/}
                              {/*      </Button>*/}
                              {/*   </Box>*/}
                              {/*)}*/}
                           </Box>
                        );
                     })}
                  </Stack>
               </Grid>
               <Grid xs={12}
md={6}>
                  {feature.image}
               </Grid>
            </Grid>
         </Container>
      </Box>
   );
};
