import type { FC } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Map from "@mui/icons-material/Map";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { HomeMapSample } from "./home-map-sample";
import DashboardImage from "/public/assets/home/dashboard.webp";
import Image from "next/image";

export const HomeHero: FC = () => {
   const theme = useTheme();

   return (
      <Box
         sx={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundImage: 'url("/assets/gradient-bg.svg")',
            pt: "120px",
         }}
      >
         <Container maxWidth="lg">
            <Box maxWidth="sm">
               <Typography variant="h1"
sx={{ mb: 2 }}>
                   Discovering and Joining Labor Unions
                  <Typography
                     component="span"
                     color="primary.main"
                     variant="inherit"
                  >
                      &nbsp;Made Easy
                  </Typography>
                  , Focus on Change.
               </Typography>
               <Typography
                  color="text.secondary"
                  sx={{
                     fontSize: 20,
                     fontWeight: 500,
                  }}
               >
                  Join the labor movement with our platform for discovering, joining
                  or even creating a local labor union. Connect with like-minded
                  individuals and take collective action for fair wages,
                  benefits, and working conditions.
               </Typography>
               <Stack
                  alignItems="center"
                  direction="row"
                  flexWrap="wrap"
                  spacing={1}
                  sx={{ my: 3 }}
               >
                  <Rating readOnly
value={4.7}
precision={0.1}
max={5} />
                  <Typography
                     color="text.primary"
                     variant="caption"
                     sx={{ fontWeight: 700 }}
                  >
                     4.7
                  </Typography>
                  {/*<Typography color="text.secondary" variant="caption">*/}
                  {/*   based on (1 review...okay fine my wife said it was cool)*/}
                  {/*</Typography>*/}
               </Stack>
               <Stack alignItems="center"
direction="row"
spacing={2}>
                  <Button
                     component={RouterLink}
                     href={paths.map}
                     startIcon={<Map />}
                     sx={(theme) =>
                        theme.palette.mode === "dark"
                           ? {
                                backgroundColor: "neutral.50",
                                color: "neutral.900",
                                "&:hover": {
                                   backgroundColor: "neutral.200",
                                },
                             }
                           : {
                                backgroundColor: "neutral.900",
                                color: "neutral.50",
                                "&:hover": {
                                   backgroundColor: "neutral.700",
                                },
                             }
                     }
                     variant="contained"
                  >
                     Live Map
                  </Button>
                  <Button
                     color="inherit"
                     component={RouterLink}
                     href={paths.dashboard.index}
                     startIcon={<KeyboardArrowRightIcon />}
                  >
                     Get Started
                  </Button>
               </Stack>
            </Box>
            <Box
               sx={{
                  pt: "120px",
                  position: "relative",
               }}
            >
               <Box
                  sx={{
                     overflow: "hidden",
                     width: "90%",
                     fontSize: 0,
                     mt: -2, // hack to cut the bottom box shadow
                     mx: -2,
                     pt: 2,
                     px: 2,
                     "& img": {
                        borderTopLeftRadius: (theme) =>
                           theme.shape.borderRadius * 2.5,
                        borderTopRightRadius: (theme) =>
                           theme.shape.borderRadius * 2.5,
                        boxShadow: 16,
                        width: "100%",
                     },
                  }}
               >
                  <Image
                     src={DashboardImage}
                     alt="Picture of a users dashboard"
                     sizes="100vw"
                     loading={"eager"}
                     priority={true}
                     style={{
                        width: "100%",
                        height: "auto",
                     }}
                  />
               </Box>

               <Box
                  sx={{
                     maxHeight: "100%",
                     maxWidth: "100%",
                     position: "absolute",
                     right: 0,
                     top: 40,
                     height: 460,
                     width: 560,
                  }}
               >
                  <HomeMapSample />
               </Box>
            </Box>
         </Container>
      </Box>
   );
};
